const trackGTMEvent = (event) => {
  if (typeof window !== 'undefined' && !!window.dataLayer && process.env.NODE_ENV === 'production') {
    if (event.ecommerce) {
      window.dataLayer.push({ ecommerce: null });
    }

    window.dataLayer.push(event);
  } else {
    console.dir(event);
  }
};

export default trackGTMEvent;
