import * as React from 'react';
import PropTypes from 'prop-types';
import { Dialog as HeadlessDialog, Transition } from '@headlessui/react';

const BaseDialog = ({ children, open, onClose }) => (
  <Transition unmount show={open} appear as={React.Fragment}>
    <HeadlessDialog as="div" className="fixed inset-0 z-50 overflow-y-auto" onClose={onClose}>
      <div className="min-h-screen max-h-screen overflow-hidden box-border relative flex justify-center items-center">
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <HeadlessDialog.Overlay className="fixed inset-0 bg-zinc-900 bg-opacity-40 transition-opacity backdrop-blur-sm" />
        </Transition.Child>

        {/* This element is to trick the browser into centering the modal contents. */}
        <span
          className="inline-block h-full max-h-full align-middle"
          aria-hidden="true"
        >
          &#8203;
        </span>
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <div className='max-h-screen box-border relative flex'>
            <div className={`
              flex flex-col w-full max-w-2xl h-full rounded-none xs:w-auto xs:h-auto xs:rounded-xl m-0 xs:m-8
              gap-2 justify-center items-center text-left px-4 py-6 xxs:px-8 xxs:py-10 md:px-12 md:py-14 bg-white
            `}>
              {children}
            </div>
          </div>
        </Transition.Child>
      </div>
    </HeadlessDialog>
  </Transition>
);

BaseDialog.propTypes = {
  children: PropTypes.node.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func
};

BaseDialog.defaultProps = {
  onClose: () => {}
};

export default BaseDialog;
