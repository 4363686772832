import React from 'react';
import PropTypes from 'prop-types';
import { ApolloProvider } from '@apollo/client';
import client from './client';

const Provider = ({ children }) => (
  <ApolloProvider client={client}>{children}</ApolloProvider>
);

Provider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Provider;