/* eslint-disable import/prefer-default-export */

const isBrowser = typeof window !== "undefined";

const getDomain = () => {
  if (isBrowser && window.location.hostname === 'localhost') { // || (isBrowser && window.location.hostname.includes('ngrok-free.app'))
    return null;
  }

  if ((isBrowser && process.env.GATSBY_NODE_ENV === 'staging') || (isBrowser && window.location.hostname === `netlify--garage-project-${process.env.GATSBY_COUNTRY.toLowerCase()}.netlify.app`)) {
    return window.location.hostname;
  }

  return process.env.GATSBY_SITE_URL;
};

export const COOKIE_OPTIONS = {
  // Disable domain enforcement when running locally (either development mode or local production build)
  domain: getDomain(),
  path: '/', // Accessible on all pages
  secure: true, // Only accessible through HTTPS
  sameSite: true
};
