// https://shopify.dev/api/examples/cart

// The CartContext provides very similar functionality to the useCart hook
// from Shopify Hydrogen: https://shopify.dev/api/hydrogen/hooks/cart/usecart

// This component was created before Shopify Hydrogen existsed, and so uses a
// slightly different syntax. It also provides greater encapsulation of the GraphQL
// queries in the Shopify API, and has additional application-specific customisation.

import * as React from 'react';
import PropTypes from 'prop-types';
import { useLocalStorage } from 'utils/frontend/storage-hooks';
import * as Sentry from '@sentry/gatsby';
import { NotificationContext } from 'context/Notification';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import CartActions from './actions';

const {
  createCart,
  retrieveCart,
  addVariant,
  removeLineItem,
  updateLineItemQuantity,
  updateOrderInstructions,
  setBuyerIdentity,
  removeBuyerIdentity,

  // Attribute mutations are renamed to avoid naming conflict with exposed actions
  setAttribute: setCartAttribute,
  removeAttribute: removeCartAttribute
} = CartActions;

const ATTRIBUTE_KEYS = {
  GIFT_NOTE_MESSAGE: "Gift note message"
};

const isBrowser = typeof window !== 'undefined';

const ssrValue = {
  ATTRIBUTE_KEYS,
  data: {
    lineItems: { nodes: [] },
  },
  initialised: true,
  updating: false,
  updatingAttribute: false,
  containsGiftNote: false,
  actions: {
    getProductQuantity: () => 0,
    getAttributeValue: () => null
  }
};

export const CartContext = React.createContext();

export const CartProvider = ({ children }) => {
  const [cart, setCart] = React.useState(null);
  const [localCartId, setLocalCartId] = useLocalStorage("shopify_cart_id");

  // Reflects when ANY update is being performed on the cart instance
  const [updating, setUpdating] = React.useState(false);

  // Reflects when a cart attribute is being updated
  // (false when inactive, or the key of the attribute being updating when active)
  const [updatingAttribute, setUpdatingAttribute] = React.useState(false);

  const { showNotification } = React.useContext(NotificationContext);

  // Create or retrieve the cart on initialisation
  React.useEffect(() => {
    const invoke = async () => {
      try {
        let initialCart;

        // Retrieve the existing cart using the shopify_cart_id local storage value if it exists
        if (localCartId) {
          initialCart = await retrieveCart(localCartId);
        }

        // If there is no cart ID stored in local storage, or if no cart was returned from the retrieval, create a new cart instead
        // (a failed retrieval can be caused if the cart has deleted either due to completing checkout, or inactivity)
        // (currently carts expire after 10 days of inactivity, refer to: https://shopify.dev/api/examples/cart#considerations)
        if (!initialCart) {
          initialCart = await createCart();
          
          // Save the cart ID in local storage
          setLocalCartId(initialCart.shopifyId);
        }

        setCart(initialCart);

      } catch (error) {
        showNotification({
          type: 'error',
          message: 'There was an error loading the cart',
        });
        Sentry.captureException(error, {
          tags: {
            action: 'initialise_cart'
          }
        });
      }
    };
    invoke();
  }, []);

  // Track state and handle errors when exposed actions are invoked
  const performAction = React.useCallback(async (invoke, sentryActionTag, notifications = {}) => {
    const toastId = notifications.invoked ? showNotification({
      type: 'loading',
      message: notifications.invoked
    }) : null;
    setUpdating(true);
    const onComplete = (result) => {
      setUpdating(false);
      return result;
    };
    try {
      const newCart = await invoke();
      setCart(newCart);
      if (notifications.success) {
        showNotification({
          type: 'success',
          message: notifications.success, 
          options: {
            id: toastId
          }
        });
      }
      return onComplete(true);
    }
    catch (error) {
      console.log('error', error);
      if (notifications.error) {
        showNotification({
          type: 'error',
          message: notifications.error,
          options: {
            id: toastId
          }
        });
      }
      Sentry.captureException(error, {
        tags: {
          action: sentryActionTag || 'cart_action'
        }
      });
      return onComplete(false);
    }
  }, [setUpdating, setCart, showNotification]);

  // Keep the buyer identity of the cart in sync with the customer identified
  // by the access token cookie
  const [cookies] = useCookies(['customer_access_token']);
  React.useEffect(() => {
    const invoke = async () => {
      if (cart) {
        const { buyerIdentity } = cart;
        const { customer } = buyerIdentity;
        const cartHasBuyerIdentity = !!customer;

        if (cartHasBuyerIdentity) {
          if (cookies.customer_access_token) {
          // Verify that the the cart buyer identity is the customer 
          // represented by the access token cookie (to prevent mismatch
          // between the buyer identity and logged in customer)
          // https://shopify.dev/api/storefront/2022-07/queries/customer

            // TODO

          } else {
          // Cart has a buyer identity but there is no access token
          // Clear buyer identity from cart
            await performAction(async () => (
              removeBuyerIdentity(cart)
            ), 'remove_cart_buyer_identity');
          }
        }
      
        else if (!cartHasBuyerIdentity && cookies.customer_access_token) {
        // Cart does not have a buyer identity but an access token exists
        // Set buyer identity on the cart
          await performAction(async () => (
            setBuyerIdentity(cart, {
              accessToken: cookies.customer_access_token
            })
          ), 'set_cart_buyer_identity');
        }
      }
    };
    invoke();
  }, [cart, cookies.customer_access_token]);

  // Shopify products that only have one default variant do not have a variant
  // name and instead use "Default Title", so this removes this trailing text
  // from the variant display name
  const cleanDisplayName = React.useCallback(displayName => (
    displayName.replace(' - Default Title', "")
  ), []);

  // Navigates the customer to the checkout once that have confirmed their cart
  const goToCheckout = React.useCallback(async () => {
    if (!cart) {
      throw Error('Cart not yet initialised');
    }

    /*
    * NOTE: There is inconsistency with the domain Shopify uses for the checkout URL
    * provided by the cart instance.
    * 
    * https://shopify.dev/api/storefront/2022-04/objects/cart#field-cart-checkouturl
    * 
    * The URL provided for the NZ store uses the <STORE_NAME>.myshopify.com domain, whereas
    * the AU store is provided with the domain of the Gatsby site (the primary site domain).
    * It's not currently known why this is the case, but the following checks the format of
    * the URL and transforms it to direct to the correct checkout location.
    */
    let { checkoutUrl } = cart;
    if (checkoutUrl.includes('myshopify.com')) {
      checkoutUrl = cart.checkoutUrl.replace(
        process.env.GATSBY_SHOPIFY_URL,
        process.env.GATSBY_SHOPIFY_CHECKOUT_URL
      );
    } else if ((new RegExp(`http(s?)://${process.env.GATSBY_SITE_URL}`)).test(checkoutUrl)) { // Test for both HTTP and HTTPS versions of the URL
      checkoutUrl = cart.checkoutUrl.replace(
        process.env.GATSBY_SITE_URL,
        process.env.GATSBY_SHOPIFY_CHECKOUT_URL
      );
    }

    /*
    * NOTE: Even though the cart will have the buyer identity set if the customer
    * is logged in, the buyer identity does not mean that the logged in state gets
    * automatically transferred to Shopify's cart/checkout page - the buyer identity
    * is primarily for localisation. To transfer the logged in state from the Gatsby/headless
    * side of the website to the Shopify hosted pages, the customer must be taken to
    * the checkout via a URL that has been multipass-bound.
    * 
    * Refer to:
    * - https://community.shopify.com/c/storefront-api-and-sdks/shopify-headless-cart-implementation-requires-users-to-login/m-p/1465970/highlight/true#M1762
    * - https://shopify.dev/api/multipass
    * - https://www.npmjs.com/package/multipassify
    */

    // Bind the customer to the checkout URL if logged in (i.e. an access token cookie exists)
    if (cookies.customer_access_token) {
      try {
        // A limitation of Gatsby Functions is that the client IP is not sent to the endpoint.
        // Therefore it is necessary to retrieve and include the client IP in the request body
        // as it is required for Shopify Multipass
        let clientIPAddress;
        try {
          const { data } = await axios.get('https://geolocation-db.com/json/');
          clientIPAddress = data.IPv4;
        } catch (error) {
          throw Error('Error fetching the client IP address');
        }

        const { data: { checkoutUrl: boundCheckoutUrl } } = await axios.post('/api/customer/bind-checkout-url', {
          checkoutUrl,
          clientIPAddress
        });

        checkoutUrl = boundCheckoutUrl;
      } catch (error) {
        // There was an error binding the user to the checkout URL, but this should
        // not prevent continuing to the checkout
        Sentry.captureException(error, {
          tags: {
            action: 'bind_checkout_url_to_customer'
          }
        });
      }
    }

    // Navigate to the checkout
    window.location.href = checkoutUrl;
  }, [cart, cookies.customer_access_token]);

  // Wraps the setCartAttribute function to enable state tracking
  const setAttribute = React.useCallback(async (_, { key, value }) => {
    setUpdatingAttribute(key);
    try {
      const newCart = await setCartAttribute(cart, {
        attribute: { key, value }
      });
      setUpdatingAttribute(false);
      return newCart;
    } catch (error) {
      setUpdatingAttribute(false);
      throw error;
    }
  }, [cart, setUpdatingAttribute]);


  // Wraps the removeCartAttribute function to enable state tracking
  const removeAttribute = React.useCallback(async (_, key) => {
    setUpdatingAttribute(key);
    try {
      const newCart = await removeCartAttribute(cart, {
        attribute: { key }
      });
      setUpdatingAttribute(false);
      return newCart;
    } catch (error) {
      setUpdatingAttribute(false);
      throw error;
    }
  }, [cart, setUpdatingAttribute]);


  const getAttributeValue = React.useCallback((key) => (
    cart ? (
      cart.attributes.find((attribute) => attribute.key === key)?.value ?? null
    ) : (
      null
    )
  ), [cart]);


  // Wrap each of the exposed actions with the above performAction function
  const actions = React.useMemo(() => (
    [
      {
        name: 'addVariant',
        func: addVariant,
        sentryActionTag: 'add_variant_to_cart',
        notifications: {
          invoked: ({ variant, quantity }) => `Adding ${quantity} x ${cleanDisplayName(variant.displayName)} to your cart...`,
          success: ({ variant, quantity }) => `Added ${quantity} x ${cleanDisplayName(variant.displayName)} to your cart!`,
          error: ({ variant, quantity }) => `Error adding ${quantity} x ${cleanDisplayName(variant.displayName)} to your cart`,
        },
      },
      {
        name: 'removeLineItem',
        func: removeLineItem,
        sentryActionTag: 'remove_cart_line_item',
        notifications: {
          invoked: ({ variant }) => `Removing ${cleanDisplayName(variant.displayName)} from your cart...`,
          success: ({ variant }) => `Removed ${cleanDisplayName(variant.displayName)} from your cart!`,
          error: ({ variant }) => `Error removing ${cleanDisplayName(variant.displayName)} from your cart`,
        },
      },
      {
        name: 'updateLineItemQuantity',
        func: updateLineItemQuantity,
        sentryActionTag: 'update_cart_line_item_quantity',
        notifications: {
          invoked: () => `Updating your cart...`,
          success: ({ variant, lineItem }) => `There ${lineItem.quantity === 1 ? 'is' : 'are'} now ${lineItem.quantity} x ${cleanDisplayName(variant.displayName)} in your cart!`,
          error: () => `Error updating cart`,
        },
      },
      {
        name: 'updateOrderInstructions',
        func: updateOrderInstructions,
        sentryActionTag: 'update_cart_order_instructions',
        notifications: {
          invoked: null,
          success: null,
          error: () => 'There was an error setting your order instructions',
        },
      },
      {
        name: 'setAttribute',
        func: setAttribute,
        sentryActionTag: 'set_cart_attribute',
        notifications: {
          invoked: null,
          success: null,
          error: () => 'There was an error updating your cart',
        },
      },
      {
        name: 'removeAttribute',
        func: removeAttribute,
        sentryActionTag: 'remove_cart_attribute',
        notifications: {
          invoked: null,
          success: null,
          error: () => 'There was an error updating your cart',
        },
      },
    ]
      .reduce((current, { name, func, sentryActionTag, notifications }) => ({
        ...current,
        [name]: (args) => (
          performAction(
            async () => func(cart, args),
            sentryActionTag,
            {
              // Evaluate the notification messages based on the a provided arguments
              // (only if a notification message evaluation function exists)
              invoked: notifications.invoked?.call(null, args),
              success: notifications.success?.call(null, args),
              error: notifications.error?.call(null, args),
            },
          )
        )
      }), {})
  ), [cart, performAction, showNotification, setUpdating]);

  // Check that there is an environment variable for the gift note product
  const giftNoteProductExists = React.useMemo(() => (
    !!process.env.GATSBY_SHOPIFY_GIFT_NOTE_PRODUCT_ID
  ), []);

  // Check if the personalised gift note is within the list of line items
  const containsGiftNote = React.useMemo(() => (!cart || !giftNoteProductExists) ? false : (
    cart.lineItems.nodes.some((lineItem) => (
      lineItem.variant.product.shopifyId === process.env.GATSBY_SHOPIFY_GIFT_NOTE_PRODUCT_ID
    ))
  ), [cart, giftNoteProductExists]);

  // If only digitial gift cards are in the cart then this will be false
  // (used to prevent adding physical gift note to digital gift cards)
  const hasPhysicalItems = React.useMemo(() => (
    !!cart?.lineItems.nodes.some(({ variant }) => variant.requiresShipping)
  ), [cart]);

  // Add the quantities for each of the line items that is a variant of the specified product
  const getProductQuantity = React.useCallback((shopifyProductId) => (
    cart ? (
      cart.lineItems.nodes.reduce((currentTotal, lineItem) => (
        lineItem.variant.product.shopifyId === shopifyProductId ? (
          currentTotal + lineItem.quantity
        ) : currentTotal
      ), 0)
    ) : 0
  ), [cart]);
  

  const value = React.useMemo(() => ({
    ATTRIBUTE_KEYS,
    data: cart,
    initialised: !!cart,
    updating,
    updatingAttribute,
    containsGiftNote,
    hasPhysicalItems,
    actions: {
      ...actions,
      getAttributeValue,
      goToCheckout,
      getProductQuantity,
    }
  }), [cart, updating, updatingAttribute, containsGiftNote, actions, goToCheckout, getProductQuantity]);

  return (
    <CartContext.Provider value={isBrowser ? value : ssrValue}>
      {children}
    </CartContext.Provider>
  );
};


CartProvider.propTypes = {
  children: PropTypes.node.isRequired
};