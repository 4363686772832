import * as React from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import { useCookies } from 'react-cookie';
import dayjs from 'utils/date';
import { COOKIE_OPTIONS } from 'utils/frontend/cookies';
import trackGTMEvent from 'utils/frontend/trackGTMEvent';
import { useMutation as useRQMutation } from 'react-query';
import axios from 'axios';
import * as Sentry from '@sentry/gatsby';
import { NotificationContext } from 'context/Notification';

export const CustomerContext = React.createContext();

export const CustomerProvider = ({ children }) => {

  const { showNotification } = React.useContext(NotificationContext);

  // ----------------------------------------------------------
  // ------ Initialised after initial check for customer ------
  // ----------------------------------------------------------
  const [initialised, setInitialised] = React.useState(false);
  // ----------------------------------------------------------


  // ----------------------------------------------------------
  // ---------- Cookie for managing logged in state -----------
  // ----------------------------------------------------------
  const [cookies, setCookie, removeCookie] = useCookies([
    'customer_access_token',
  ]);
  const setAccessToken = (customerAccessToken) => {
    const { accessToken: token, expiresAt } = customerAccessToken;
    setCookie('customer_access_token', token,
      {
        expires: dayjs(expiresAt).toDate(),
        ...COOKIE_OPTIONS
      }
    );
  };
  // ---------------------------------------------------------- 




  // ----------------------------------------------------------
  // ---------------------- Load customer ---------------------
  // ----------------------------------------------------------
  const {
    data: customerResponse,
    mutate: loadCustomer,
    isLoading: loadingCustomer,
    reset: resetCustomer,
  } = useRQMutation(() =>  axios.get('/api/customer'), {
    onError: error => {
      Sentry.captureException(error, {
        tags: {
          action: 'load_customer'
        }
      });

      // If the customer fails to load, clear the saved access token to
      // to eliminate the token being the cause of the issue
      removeCookie('customer_access_token', COOKIE_OPTIONS);
      resetCustomer();
      showNotification({
        type: 'error',
        message: 'There was a problem loading your account details, please try logging in again.',
      });
    }
  });

  // Fetch the customer if a token exists on mount, or when a
  // token has been set after login
  React.useEffect(() => {
    if (cookies.customer_access_token) {
      loadCustomer();
    }
    // If not initialised and there is no access token, then the
    // initialisation is complete
    else if (!initialised) {
      setInitialised(true);
    }
  }, [cookies.customer_access_token]);

  // Set as initialised once the customer has loaded
  React.useEffect(() => {
    if (customerResponse && !initialised) {
      setInitialised(true);
    }
    if (customerResponse?.data?.shopifyId) {
      trackGTMEvent({
        user_id: customerResponse?.data?.shopifyId.replace(/^gid:\/\/shopify\/[a-zA-Z]+\//, ''),
      });
    }
  }, [customerResponse, initialised]);

  // Reloading can be used to refresh the customer data
  const reload = React.useCallback(() => {
    setInitialised(false);
    loadCustomer();
  }, [setInitialised, loadCustomer]);
  // ----------------------------------------------------------


  // ----------------------------------------------------------
  // ------------------------ Logout --------------------------
  // ----------------------------------------------------------
  const logout = React.useCallback(() => {
    removeCookie('customer_access_token', COOKIE_OPTIONS);
    resetCustomer();
    navigate('/account/login');
  }, [resetCustomer, removeCookie]);
  // ----------------------------------------------------------


  const loggedIn = React.useMemo((
    () => initialised && !!customerResponse?.data
  ), [initialised, customerResponse]);

  // The customer can add to their subscription if they have one or 
  // more active subscriptions that are eligible for onetime items
  const canAddToFreshClub = React.useMemo((
    () => (
      initialised
      && loggedIn
      && customerResponse.data.isRechargeCustomer
      && customerResponse.data.subscriptions.freshClub.some(
        ({ eligibleForOnetimes }) => eligibleForOnetimes
      )
    )
  ), [initialised, loggedIn, customerResponse]);


// 

  const isFreshSubscriber = React.useMemo((
    () => (
      initialised
      && loggedIn
      && customerResponse.data.isRechargeCustomer
      && customerResponse.data.isFreshClubSubscriber
    )
  ), [initialised, loggedIn, customerResponse]);


  // ----------------------------------------------------------
  // -------------------- Context provision -------------------
  // ----------------------------------------------------------
  const contextValue = React.useMemo(() => ({
    data: customerResponse ? customerResponse.data : null,
    initialised,
    loggingIn: loadingCustomer,
    loggedIn,
    isFreshSubscriber,
    canAddToFreshClub,
    actions: {
      setAccessToken,
      logout,
      reload
    },
  }), [
    initialised,
    customerResponse,
    loadingCustomer,
    canAddToFreshClub,
    isFreshSubscriber,
    setAccessToken,
    logout
  ]);

  return (
    <CustomerContext.Provider value={contextValue}>
      {children}
    </CustomerContext.Provider>
  );
  // ----------------------------------------------------------
};

CustomerProvider.propTypes = {
  children: PropTypes.node.isRequired
};