import updateCartBuyerIdentity from './_update';

/**
 * Remove the buyer identity from the cart.
 * @param {Object} cart - The cart to remove the buyer identity from.
 * @param {string} cart.shopifyId - The Shopify ID of the cart.
 */

export default async (cart) => (
  updateCartBuyerIdentity(cart, {
    accessToken: null
  })
);