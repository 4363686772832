import { gql } from '@apollo/client';
import apolloClient from 'apollo/client';
import { CART_FIELDS } from '../fragments';

/**
 * Updates the buyer identity on the cart.
 * @param {Object} cart - The cart to update the buyer identity on.
 * @param {string} cart.shopifyId - The Shopify ID of the cart.
 * @param {string} customer - The customer to update the buyer identity to.
 * @param {string} customer.accessToken - The access token for the customer.
 */

// https://shopify.dev/api/storefront/2022-04/mutations/cartBuyerIdentityUpdate
const MUTATION = gql`
  ${CART_FIELDS} # Include fragment for cart fields
  mutation cartBuyerIdentityUpdate($cartId: ID!, $buyerIdentity: CartBuyerIdentityInput!) {
    cartBuyerIdentityUpdate(buyerIdentity: $buyerIdentity, cartId: $cartId) {
      cart {
        ...CartFields
      }
      userErrors {
        field
        message
      }
    }
  }
`;

export default async (cart, customer) => {
  const { data: { cartBuyerIdentityUpdate } } = await apolloClient.mutate({
    mutation: MUTATION,
    variables: {
      cartId: cart.shopifyId,
      buyerIdentity: {
        customerAccessToken: customer.accessToken
      }
    }
  });

  const { userErrors } = cartBuyerIdentityUpdate;
  if (userErrors.length) {
    const [error] = userErrors;
    throw error.message;
  }
  
  const { cart: newCart } = cartBuyerIdentityUpdate;
  return newCart;
};