import React from 'react';
import PropTypes from 'prop-types';
import { Location } from '@gatsbyjs/reach-router';
import { Link } from 'gatsby';

const LinkWithPrevUrl = ({ children, state, ...rest }) => (
  <Location>
    {({ location }) => (
      <Link {...rest} state={{ prevUrl: location.href, ...state }}>
        { children }
      </Link>
    )}
  </Location>
);

LinkWithPrevUrl.propTypes = {
  children: PropTypes.node.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  state: PropTypes.object
};

LinkWithPrevUrl.defaultProps = {
  state: {}
};

export default LinkWithPrevUrl;