import createCart from "./cart/create";
import retrieveCart from "./cart/retrieve";
import addVariant from './line-items/add';
import removeLineItem from './line-items/remove';
import updateLineItemQuantity from './line-items/update-quantity';
import setAttribute from './attributes/set';
import removeAttribute from './attributes/remove';
import updateOrderInstructions from './order-instructions/update';
import setBuyerIdentity from './buyer-identity/set';
import removeBuyerIdentity from './buyer-identity/remove';

export default {
  createCart,
  retrieveCart,
  addVariant,
  removeLineItem,
  updateLineItemQuantity,
  updateOrderInstructions,
  setAttribute,
  removeAttribute,
  setBuyerIdentity,
  removeBuyerIdentity
};