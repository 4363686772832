const dayjs = require('dayjs');
const customParseFormat = require('dayjs/plugin/customParseFormat');
const advancedFormat = require('dayjs/plugin/advancedFormat');
const utc = require('dayjs/plugin/utc');
const timezone = require('dayjs/plugin/timezone');
const localeData = require('dayjs/plugin/localeData');
const updateLocale = require('dayjs/plugin/updateLocale');
const weekday = require('dayjs/plugin/weekday');

dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localeData);
dayjs.extend(updateLocale);
dayjs.extend(weekday);

dayjs.tz.setDefault(process.env.GATSBY_TIMEZONE);

dayjs.updateLocale('en', {
  weekdays: [
    "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"
  ]
});

module.exports = dayjs;