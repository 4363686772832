import * as React from 'react';
import PropTypes from 'prop-types';
import LinkButton from 'components/LinkButton';
import Logo from 'images/svg/garage-project-logo.svg';
import { useSessionStorage } from 'utils/frontend/storage-hooks';
import useRenderEnvironment from 'utils/frontend/use-render-environment';
import BaseDialog from 'components/BaseDialog';
import isbot from 'isbot';

const AgeConfirmation = ({ children }) => {
  const [open, setOpen] = React.useState(false);
  const renderEnvironment = useRenderEnvironment();
  const [ageConfirmed, setAgeConfirmed] = useSessionStorage("age_confirmed");

  React.useEffect(() => {
    if (renderEnvironment.isClient) {
      if (ageConfirmed) {
        setOpen(false);
      } else if (!isbot(navigator.userAgent)) {
        setOpen(true);
      }
    }
  }, [renderEnvironment, ageConfirmed]);

  const confirmAge = React.useCallback((
    () => setAgeConfirmed(true)
  ), [setAgeConfirmed]);

  return (
    <>
      <BaseDialog open={open}>
        <div className='flex flex-col gap-2 text-center justify-center items-center'>
          <Logo className="h-14 mb-6"/>
          <h1 className='text-2xl text-zinc-900 font-semibold'>
            Are you over 18?
          </h1>
          <p className='max-w-lg mb-6 text-zinc-500'>
            We sell alcohol on this website which means that you must be at least 18 years of age to continue.
            Please confirm your age below.
          </p>
          <button
            type='button'
            className='btn btn--gpred mb-2'
            onClick={confirmAge}
          >
            Yes, I&apos;m 18 or over
          </button>
          <LinkButton
            type='standard-link'
            href={process.env.GATSBY_COUNTRY === 'NZ' ? (
              "https://www.toyworld.co.nz/"
            ) : (
              "https://www.toyworld.com.au/"
            )}
          >
            No, I&apos;m under 18
          </LinkButton>
        </div>
      </BaseDialog>

      {/* Page content underneath the confirmation overlay */}
      { children }
    </>
  );
};

AgeConfirmation.propTypes = {
  children: PropTypes.node.isRequired
};

export default AgeConfirmation;
