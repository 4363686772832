// https://shopify.dev/custom-storefronts/customer-accounts
// https://javascript.plainenglish.io/how-to-query-remote-graphql-data-with-apollo-in-gatsby-d199a3e618d0

import * as React from 'react';
import PropTypes from 'prop-types';
import toast, { Toaster } from 'react-hot-toast';
import { useMediaQuery } from 'react-responsive';
import { HiExclamationCircle } from 'react-icons/hi';

// Getting the y position of the bottom of the header enables rendering the 
// notifications directly below. This is necessary since the header height can
// change dynamically due to the announcement banner.
const getBottomOfHeader = () => {
  const headerElement = document.getElementById('header');
  return headerElement.offsetTop + headerElement.offsetHeight;
};

let activeToastId = null;

export const NotificationContext = React.createContext();

export const NotificationProvider = ({ children }) => {

  const rightSide = useMediaQuery({ query: '(min-width: 768px)' }); // Tailwind md breakpoint

  const [yOffset, setYOffset] = React.useState(0);

  const value = React.useMemo(() => ({
    showNotification: ({ type, message, options = {} }) => {
      setYOffset(getBottomOfHeader());
      let createToast;
      let { icon } = options;
      
      // Add custom warning type
      if (type === 'warning') {
        icon = <HiExclamationCircle className='text-amber-500 flex-shrink-0 w-6 h-6' />;
        createToast = toast;
      } else {
        createToast = type ? toast[type] : toast;
      }

      if (activeToastId) {
        toast.remove(activeToastId);
      }

      activeToastId = createToast(message, {
        ...options,
        icon,
        // Add any options to apply to all notifications here
        position: rightSide ? 'top-right' : 'top-center', 
        duration: 5000,
        className: 'max-w-none min-w-full md:min-w-0'
      });

      return activeToastId;
    }
  }), [setYOffset, rightSide, getBottomOfHeader]);

  return (
    <NotificationContext.Provider value={value}>
      
      {children}

      <div id='toaster' className='z-50 fixed top-0 w-full h-full box-border pointer-events-none'>
        <div
          className="w-full h-full pr-12 pl-4 overflow-hidden"
          style={{ paddingTop: yOffset }}
        >
          <Toaster
            containerStyle={{
              position: 'relative'
            }}
          />
        </div>
      </div>
    </NotificationContext.Provider>
  );
};

NotificationProvider.propTypes = {
  children: PropTypes.node.isRequired
};