import { gql } from '@apollo/client';
import apolloClient from 'apollo/client';
import { CART_FIELDS } from '../fragments';

/**
 * Update the quantity of a line item in the cart.
 * @param {Object} cart - The cart containing the line item.
 * @param {string} cart.shopifyId - The Shopify ID of the cart.
 * @param {Object} lineItem - The line item in the cart to be updated.
 * @param {string} lineItem.shopifyId - The Shopify ID of the line item.
 * @param {number} lineItem.quantity - The new quantity to set for the line item.
 * @param {Object} variant - The variant that the line item is composed of.
 * @param {string} variant.shopifyId - The Shopify ID of the variant.
 */

// https://shopify.dev/api/storefront/2022-04/mutations/cartLinesUpdate
const MUTATION = gql`
  ${CART_FIELDS} # Include fragment for cart fields
  mutation cartLinesUpdate($cartId: ID!, $lines: [CartLineUpdateInput!]!) {
    cartLinesUpdate(cartId: $cartId, lines: $lines) {
      cart {
        ...CartFields
      }
      userErrors {
        field
        message
      }
    }
  }
`;

export default async (cart, { lineItem, variant }) => {
  const { data: { cartLinesUpdate } } = await apolloClient.mutate({
    mutation: MUTATION,
    variables: {
      cartId: cart.shopifyId,
      lines: [{
        id: lineItem.shopifyId,
        quantity: lineItem.quantity,
        merchandiseId: variant.shopifyId,
      }]
    }
  });

  const { userErrors } = cartLinesUpdate;
  if (userErrors.length) {
    const [error] = userErrors;
    throw error.message;
  }

  // TODO: Handle update successful, but actual quantity less than requested due to limited inventory
  
  const { cart: newCart } = cartLinesUpdate;
  return newCart;
};