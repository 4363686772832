import * as React from 'react';
import PropTypes from 'prop-types';
import * as Sentry from "@sentry/gatsby";
import { isMobile } from 'react-device-detect';
import { CustomerContext } from 'context/Customer';
import GarageProjectLogo from 'images/svg/garage-project-logo.svg';

const TITLE = "Sorry, something went wrong.";
const SUBTITLE = "Our team has been notified and we'll get this fixed as soon as possible. If you'd like to help, that'd be awesome! Please describe what happened below."
const SUCCESS_MESSAGE = "Cheers for that! We appreciate it, you've helped to make this site better."

const goBackHome = () => {
  window.location.href = '/'
};

const Fallback = ({ showLogo }) => (
  <div className='bg-zinc-100 w-full h-screen flex flex-col justify-center items-center gap-y-4 px-8'>
    {showLogo && <GarageProjectLogo className="h-18 mb-6"/>}
    <div className="flex flex-col items-center gap-y-2 mb-2">
      <span className="text-2xl font-semibold">
        {TITLE}
      </span>
      <span className='text-center'>
        {`Please ${isMobile ? 'tap' : 'click'} below to head back to the homepage.`}
      </span>
    </div>
    <button
      type="button"
      className='btn btn--black'
      onClick={goBackHome}
    >
      Go to homepage
    </button>
  </div>
);

Fallback.propTypes = {
  showLogo: PropTypes.bool.isRequired
}

const ErrorBoundary = ({ children, container, showLogo }) => {
  const customer = React.useContext(CustomerContext);
  return (
    <Sentry.ErrorBoundary
      showDialog
      dialogOptions={{
        title: TITLE,
        subtitle: SUBTITLE,
        subtitle2: "",
        successMessage: SUCCESS_MESSAGE,
        user: customer && customer.loggedIn ? {
          email: customer.data.email,
          name: customer.data.displayName,
        } : null
      }}
      fallback={<Fallback showLogo={showLogo}/>}
      beforeCapture={(scope) => {
        scope.setTag("container", container);
      }}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
  container: PropTypes.string.isRequired,
  showLogo: PropTypes.bool,
};

ErrorBoundary.defaultProps = {
  showLogo: false
}

export default ErrorBoundary;
