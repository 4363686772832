import React from 'react';
import PropTypes from 'prop-types';
import { Loader } from '@googlemaps/js-api-loader';

const loader = new Loader({
  apiKey: process.env.GATSBY_GOOGLE_MAPS_API_KEY,
  version: 'weekly',
  libraries: ['places', 'drawing'],
});

export const GoogleMapsContext = React.createContext();

export const GoogleMapsProvider = ({ children }) => {
  const [instance, setInstance] = React.useState(null);

  const load = React.useCallback(async () => {

    // Already loaded
    if (instance) {
      console.log('Google Maps has already been loaded');
      return;
    }

    try {
      setInstance(await loader.load());
    } catch (error) {
      console.log('Error loading the Google Maps JavaScript API script', error);
      // TODO: Log in Sentry
    }
  }, [loader]);

  const value = React.useMemo(() => ({
    load,
    loaded: !!instance,
    instance,
  }), [load, instance]);

  return (
    <GoogleMapsContext.Provider value={value}>
      {children}
    </GoogleMapsContext.Provider>
  );
};

GoogleMapsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
