import * as React from 'react';
import PropTypes from 'prop-types';

export const ScrollContext = React.createContext();

export const ScrollProvider = ({ children }) => {
  const scrollContainerRef = React.useRef();

  const [atTopOfPage, setAtTopOfPage] = React.useState(true);

  const scrollToTop = React.useCallback(() => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.container.firstChild.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [scrollContainerRef]);

  const updateScrollLocation = React.useCallback((restorationRef) => {

    if (restorationRef?.current) {
      if (restorationRef.current.scrollTop === 0) {
        if (!atTopOfPage) {
          setAtTopOfPage(true);
        }
      } else if (atTopOfPage) {
        setAtTopOfPage(false);
      }
    }

    if (scrollContainerRef.current) {
      if (scrollContainerRef.current.container.firstChild.scrollTop === 0) {
        if (!atTopOfPage) {
          setAtTopOfPage(true);
        }
      } else if (atTopOfPage) {
        setAtTopOfPage(false);
      }
    }
  }, [scrollContainerRef.current, atTopOfPage, setAtTopOfPage]);

  const value = React.useMemo(() => ({
    scrollContainerRef,
    updateScrollLocation,
    scrollToTop,
    atTopOfPage,
  }), [atTopOfPage]);

  return (
    <ScrollContext.Provider value={value}>
      {children}
    </ScrollContext.Provider>
  );
};

ScrollProvider.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.object.isRequired
};