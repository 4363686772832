/* eslint-disable react/jsx-filename-extension */
import * as React from 'react';
import * as Sentry from '@sentry/gatsby';
import { BrowserTracing } from "@sentry/tracing";
import Div100vh from 'react-div-100vh';
import ApolloProvider from 'apollo/Provider';
import { CartProvider } from 'context/Cart';
import { CustomerProvider } from 'context/Customer';
import { CookieConsentProvider } from 'context/CookieConsent';
import { NotificationProvider } from 'context/Notification';
import { GoogleMapsProvider } from 'context/GoogleMaps';
import { CookiesProvider } from 'react-cookie';
import { QueryClient, QueryClientProvider } from 'react-query';
import ErrorBoundary from 'components/ErrorBoundary';
import AgeConfirmation from './src/components/AgeConfirmation';

import './src/styles/global.css';
import './src/fonts/sofia-pro/index.css';
import './src/fonts/treehugger/index.css';
import 'react-loading-skeleton/dist/skeleton.css';
import 'react-image-lightbox/style.css';
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css';

import { ScrollProvider } from './src/context/Scroll';

// Use Sentry only in staging and production environments
if (process.env.GATSBY_NODE_ENV !== 'development') {
  Sentry.init({
    dsn: "https://a9d770c0452340128063d4dad3797524@o992371.ingest.sentry.io/6247727",
    sampleRate: process.env.GATSBY_SENTRY_SAMPLE_RATE,
    environment: process.env.GATSBY_NODE_ENV,
    initialScope: {
      tags: {
        "store_country": process.env.GATSBY_COUNTRY,
        "shopify_store_name": process.env.GATSBY_SHOPIFY_STORE_NAME,
        "shopify_api_version": process.env.GATSBY_SHOPIFY_API_VERSION,
      },
    },
    integrations: [new BrowserTracing()],
    ignoreErrors: ['ResizeObserver loop limit exceeded'],
  });
}

const queryClient = new QueryClient();


// eslint-disable-next-line import/prefer-default-export
export const wrapPageElement = ({ element }) => (
  // The primary error boundary is located in MainLayout, so that the page layout
  // can be preserved. However if the error occurs outside of the MainLayout error
  // boundary, then the error boundary below is the second layer of defense
  <ErrorBoundary container="root">
    <ScrollProvider>
      <CookiesProvider>
        <CookieConsentProvider>
          <QueryClientProvider client={queryClient}>
            <ApolloProvider>
              <NotificationProvider>
                <GoogleMapsProvider>
                  <CartProvider>
                    <CustomerProvider>
                      <Div100vh>
                        <AgeConfirmation>
                          {element}
                        </AgeConfirmation>
                      </Div100vh>
                    </CustomerProvider>
                  </CartProvider>
                </GoogleMapsProvider>
              </NotificationProvider>
            </ApolloProvider>
          </QueryClientProvider>
        </CookieConsentProvider>
      </CookiesProvider>
    </ScrollProvider>
  </ErrorBoundary>
);

const redirectIfInMaintanceMode = () => {
  if (process.env.GATSBY_MAINTENANCE_MODE === 'true') {
    if (window.location.pathname !== '/maintenance') {
      window.location.href = '/maintenance';
    }
  } else if (window.location.pathname === '/maintenance') {
    window.location.href = '/';
  }
};

export const onClientEntry = redirectIfInMaintanceMode;
export const onRouteUpdate = redirectIfInMaintanceMode;