import { gql } from '@apollo/client';
import apolloClient from 'apollo/client';
import { CART_FIELDS } from '../fragments';

/**
 * Updates the note on the cart.
 * The note is referred to as order instructions to avoid confusion with the gift note cart attribute.
 * @param {Object} cart - The cart to update the note on.
 * @param {string} cart.shopifyId - The Shopify ID of the cart.
 * @param {string} orderInstructions - The message to set as the order instructions.
 */

// https://shopify.dev/api/storefront/2022-04/mutations/cartNoteUpdate
const MUTATION = gql`
  ${CART_FIELDS} # Include fragment for cart fields
    mutation cartNoteUpdate($cartId: ID!, $orderInstructions: String!) {
    cartNoteUpdate(cartId: $cartId, note: $orderInstructions) {
      cart {
        ...CartFields
      }
      userErrors {
        field
        message
      }
    }
  }
`;

export default async (cart, orderInstructions) => {
  const { data: { cartNoteUpdate } } = await apolloClient.mutate({
    mutation: MUTATION,
    variables: {
      cartId: cart.shopifyId,
      orderInstructions
    }
  });

  const { userErrors } = cartNoteUpdate;
  if (userErrors.length) {
    const [error] = userErrors;
    throw error.message;
  }
  
  const { cart: newCart } = cartNoteUpdate;
  return newCart;
};