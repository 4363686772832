import updateCartAttributes from './_update';

/**
 * Remove an attribute from cart.
 * @param {Object} cart - The cart to remove the attribute from.
 * @param {string} cart.shopifyId - The Shopify ID of the cart.
 * @param {Object} attribute - The attribute to be set on the cart.
 * @param {string} attribute.key - The attribute key.
 */

export default async (cart, { attribute }) => {
  const { attributes: existingAttributes } = cart;
  const newAttributes = existingAttributes.filter(({ key }) => (
    key !== attribute.key
  ));
  return (
    updateCartAttributes(cart, {
      attributes: newAttributes
    })
  )
};