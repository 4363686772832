
import { gql } from '@apollo/client';
import apolloClient from 'apollo/client';
import { CART_FIELDS } from '../fragments';

/**
 * Create a new Shopify cart.
 */

// https://shopify.dev/api/storefront/2022-04/mutations/cartCreate
const MUTATION = gql`
  ${CART_FIELDS} # Include fragment for cart fields
  mutation cartCreate {
    cartCreate {
      cart {
        ...CartFields
      }
      userErrors {
        field
        message
      }
    }
  }
`;

export default async () => {
  const { data: { cartCreate } } = await apolloClient.mutate({
    mutation: MUTATION,
  });

  const { userErrors } = cartCreate;
  if (userErrors.length) {
    const [error] = userErrors;
    throw error.message;
  }
  
  const { cart } = cartCreate;
  return cart;
};