import { gql } from '@apollo/client';
import apolloClient from 'apollo/client';
import { CART_FIELDS } from '../fragments';
import trackGTMEvent from 'utils/frontend/trackGTMEvent';

/**
 * Remove a line item from the cart.
 * @param {Object} cart - The cart to remove the line item from.
 * @param {string} cart.shopifyId - The Shopify ID of the cart.
 * @param {Object} lineItem - The line item to be removed from the cart.
 * @param {string} lineItem.shopifyId - The Shopify ID of the line item.
 */

// https://shopify.dev/api/storefront/2022-04/mutations/cartLinesRemove
const MUTATION = gql`
  ${CART_FIELDS} # Include fragment for cart fields
  mutation cartLinesRemove($cartId: ID!, $lineIds: [ID!]!) {
    cartLinesRemove(cartId: $cartId, lineIds: $lineIds) {
      cart {
        ...CartFields
      }
      userErrors {
        field
        message
      }
    }
  }
`;

export default async (cart, { lineItem, variant }) => {
  const { data: { cartLinesRemove } } = await apolloClient.mutate({
    mutation: MUTATION,
    variables: {
      cartId: cart.shopifyId,
      lineIds: [lineItem.shopifyId]
    }
  });

  const { userErrors } = cartLinesRemove;
  if (userErrors.length) {
    const [error] = userErrors;
    throw error.message;
  }

  trackGTMEvent({
    event: 'remove_from_cart',
    ecommerce: {
      items: [{
        item_id: variant.sku,
        item_name: `${variant?.product.title} - ${variant.title}`,
        price: (parseFloat(variant.priceV2?.amount)).toFixed(2),
      }]
    },
  });
  
  const { cart: newCart } = cartLinesRemove;
  return newCart;
};