// https://usehooks.com/useLocalStorage/

import React from 'react';

const isBrowser = typeof window !== 'undefined';

const useStorage = (key, initialValue, local) => {

  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = React.useState(() => {
    if (!isBrowser) {
      return initialValue;
    }
    try {
      // Get from storage by key
      const item = (local ? window.localStorage : window.sessionStorage).getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      console.log(error);
      return initialValue;
    }
  });

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to storage.
  const setValue = (value) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to storage
      if (isBrowser) {
        (local ? window.localStorage : window.sessionStorage).setItem(key, JSON.stringify(valueToStore));
      }
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error);
    }
  };
  return [storedValue, setValue];
};

export const useSessionStorage = (key, initialValue) => useStorage(key, initialValue, false);
export const useLocalStorage = (key, initialValue) => useStorage(key, initialValue, true);