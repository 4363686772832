import updateCartAttributes from './_update';

/**
 * Set an attribute on the cart.
 * @param {Object} cart - The cart to set the attribute on.
 * @param {string} cart.shopifyId - The Shopify ID of the cart.
 * @param {Object} attribute - The attribute to be set on the cart.
 * @param {string} attribute.key - The attribute key.
 * @param {string} attribute.value - The attribute value.
 */

export default async (cart, { attribute }) => {
  const { attributes: existingAttributes } = cart;
  const newAttributes = [
    // Filter out the attribute by key first in case it already exists
    ...existingAttributes.filter(({ key }) => (
      key !== attribute.key
    )),
    // Add the attribute
    attribute
  ];
  return (
    updateCartAttributes(cart, {
      attributes: newAttributes
    })
  )
};