/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const CART_FIELDS = gql`
  # https://shopify.dev/api/storefront/2022-04/objects/Cart#fields
  fragment CartFields on Cart {
    shopifyId: id
    checkoutUrl
    # The note is referred to as order instructions to avoid confusion with the gift note cart attribute
    orderInstructions: note
    attributes {
      key
      value
    }
    estimatedCost {
      totalAmount {
        amount
        currencyCode
      }
      subtotalAmount {
        amount
        currencyCode
      }
    }

    # https://shopify.dev/api/storefront/2022-04/objects/CartBuyerIdentity
    buyerIdentity {
      customer {
        shopifyId: id,
        email
      }
    }

    # https://shopify.dev/api/storefront/2022-04/objects/CartLine#fields
    lineItems: lines(first: 250) {
      nodes {
        shopifyId: id
        quantity
        attributes {
          key
          value
        }
        estimatedCost {
          totalAmount {
            amount
            currencyCode
          }
          subtotalAmount {
            amount
            currencyCode
          }
        }
        variant: merchandise {
          ... on ProductVariant {
            shopifyId: id,
            title
            sku
            requiresShipping
            priceV2 {
              amount
              currencyCode
            }
            compareAtPriceV2 {
              amount
              currencyCode
            }
            image {
              url
            }
            product {
              shopifyId: id,
              title
              handle
              # The "max_per_order" metafield needs to be explicitly exposed to the storefront API
              # Refer to: https://shopify.dev/api/examples/metafields#step-1-expose-metafields
              maxPerOrder: metafield(
                namespace: "global",
                key: "max_per_order"
              ) {
                value
                type
              }
            }
          }
        }
      }
    }
  }
`;