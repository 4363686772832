import { gql } from '@apollo/client';
import apolloClient from 'apollo/client';
import trackGTMEvent from 'utils/frontend/trackGTMEvent';
import { CART_FIELDS } from '../fragments';

/**
 * Add a product variant to the cart to create (or add to) a line item.
 * @param {Object} cart - The cart to add the create the line item for.
 * @param {string} cart.shopifyId - The Shopify ID of the cart.
 * @param {Object} variant - The variant to be added to the cart.
 * @param {string} variant.shopifyId - The Shopify ID of the variant.
 * @param {number} quantity - The quantity of the variant to add.
 */

// https://shopify.dev/api/storefront/2022-04/mutations/cartLinesAdd
const MUTATION = gql`
  ${CART_FIELDS} # Include fragment for cart fields
  mutation cartLinesAdd($cartId: ID!, $lines: [CartLineInput!]!) {
    cartLinesAdd(cartId: $cartId, lines: $lines) {
      cart {
        ...CartFields
      }
      userErrors {
        field
        message
      }
    }
  }
`;

export default async (cart, { variant, quantity }) => {
  const response = await apolloClient.mutate({
    mutation: MUTATION,
    variables: {
      cartId: cart.shopifyId,
      lines: [{
        quantity,
        merchandiseId: variant.shopifyId,
      }]
    }
  });

  const { data: { cartLinesAdd } } = response;

  const { userErrors } = cartLinesAdd;
  if (userErrors.length) {
    const [error] = userErrors;
    throw error.message;
  }
  
  const { cart: newCart } = cartLinesAdd;

  trackGTMEvent({
    event: 'add_to_cart',
    ecommerce: {
      items: [{
        item_id: variant.sku,
        item_name: `${variant?.product?.title} - ${variant?.title}`,
        price: variant.price,
      }]
    },
  });

  return newCart;
};