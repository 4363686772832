import updateCartBuyerIdentity from './_update';

/**
 * Set a buyer identity on the cart.
 * @param {Object} cart - The cart to remove the buyer identity from.
 * @param {string} cart.shopifyId - The Shopify ID of the cart.
 * @param {string} customer - The customer to set as the buyer identity.
 * @param {string} customer.accessToken - The access token for the customer.
 */

export default async (cart, customer) => (
  updateCartBuyerIdentity(cart, customer)
);