import { gql } from '@apollo/client';
import apolloClient from 'apollo/client';
import { CART_FIELDS } from '../fragments';

/**
 * Retrieve an existing Shopify cart.
  * @param {string} cart - The Shopify ID of the cart to retrieve.
 */

// https://shopify.dev/api/storefront/2022-04/queries/cart
const QUERY = gql`
  ${CART_FIELDS} # Include fragment for cart fields
  query cart($cartId: ID!) {
    cart(id: $cartId) {
      ...CartFields
    }
  }
`;

export default async (cartId) => {
  const response = await apolloClient.query({
    query: QUERY,
    variables: {
      cartId,
    },
  });

  const { data } = response;

  return data.cart;
};