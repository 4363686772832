import { gql } from '@apollo/client';
import apolloClient from 'apollo/client';
import { CART_FIELDS } from '../fragments';

/**
 * Updates the attributes on the cart.
 * @param {Object} cart - The cart to update the attributes on.
 * @param {string} cart.shopifyId - The Shopify ID of the cart.
 * @param {Object[]} attributes - The attributes to update the cart with.
 * @param {string} attributes[].key - The key of an attribute.
 * @param {string} attributes[].value - The value of an attribute.
 */

// https://shopify.dev/api/storefront/2022-04/mutations/cartAttributesUpdate
const MUTATION = gql`
  ${CART_FIELDS} # Include fragment for cart fields
  mutation cartAttributesUpdate($cartId: ID!, $attributes: [AttributeInput!]!) {
    cartAttributesUpdate(attributes: $attributes, cartId: $cartId) {
      cart {
        ...CartFields
      }
      userErrors {
        field
        message
      }
    }
  }
`;

export default async (cart, { attributes }) => {
  const { data: { cartAttributesUpdate } } = await apolloClient.mutate({
    mutation: MUTATION,
    variables: {
      cartId: cart.shopifyId,
      attributes
    }
  });

  const { userErrors } = cartAttributesUpdate;
  if (userErrors.length) {
    const [error] = userErrors;
    throw error.message;
  }
  
  const { cart: newCart } = cartAttributesUpdate;
  return newCart;
};