import fetch from 'isomorphic-fetch';
import { ApolloClient, InMemoryCache } from '@apollo/client';

/*
* Apollo is used for Shopify storefront API requests in the frontend rather than @shopify/shopify-api
* (which is used by the backend), due to richer frontend features provided through Apollo (e.g. React hooks)
*/

const client = new ApolloClient({
  fetch,
  uri: `https://${process.env.GATSBY_SHOPIFY_URL}/api/${process.env.GATSBY_SHOPIFY_API_VERSION}/graphql.json`, 
  cache: new InMemoryCache(),
  headers: {
    // authorization: localStorage.getItem('token'), // Maybe once logged in?
    'X-Shopify-Storefront-Access-Token': process.env.GATSBY_STOREFRONT_ACCESS_TOKEN
  }
});

export default client;