// https://shopify.dev/custom-storefronts/customer-accounts
// https://javascript.plainenglish.io/how-to-query-remote-graphql-data-with-apollo-in-gatsby-d199a3e618d0

import * as React from 'react';
import PropTypes from 'prop-types';
import { StaticImage } from "gatsby-plugin-image";
import { Transition } from '@headlessui/react';
import { useLocalStorage } from 'utils/frontend/storage-hooks';
import isbot from 'isbot';

const isBrowser = typeof window !== 'undefined';

export const CookieConsentContext = React.createContext();

export const CookieConsentProvider = ({ children }) => {
  const [acceptedCookies, setAcceptedCookies] = useLocalStorage("accepted_cookies");
  const [popupOpen, setPopupOpen] = React.useState(false);

  React.useEffect(() => {
    if (isBrowser && !navigator.cookieEnabled) {
      // TODO: Tell user that they need to enable cookies
    }

    if (!acceptedCookies) {
      setPopupOpen(true);
    }
  }, []);

  const onAcceptCookies = () => {
    setPopupOpen(false);
    setAcceptedCookies(true);
  };

  // Set with useMemo due to: react/jsx-no-constructed-context-values
  const value = React.useMemo(() => ({
    acceptedCookies: isBrowser ? acceptedCookies : true // If SSR, then set age as confirmed by default
  }), [acceptedCookies]);

  // Do not show the cookies consent popup when SSRing or for for bots/crawlers
  const hidden = React.useMemo(() => (
    !isBrowser || (isBrowser && isbot(navigator.userAgent))
  ), []);

  return (
    <CookieConsentContext.Provider value={value}>

      <Transition
        className={`${hidden ? 'hidden' : 'flex'} fixed z-50 flex flex-row items-center bottom-0 left-0 max-w-none md:max-w-xl md:bottom-6 md:left-6 rounded-none md:rounded-lg bg-white shadow-2xl text-sm p-4`}
        show={popupOpen}
        enter="transition-all transform duration-500 ease-out"
        enterFrom="opacity-0 translate-y-full"
        enterTo="opacity-100 translate-y-0"
        leave="transition-all transform duration-500 ease-out"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-full"
      >
        <StaticImage
          className="h-14 mr-3"
          src="../images/characters/cookie.png"
          alt="Cookie illustration"
          objectFit='contain'
          placeholder='blurred'
        />
        <span>
          By using this website, you agree to our use of cookies. We use cookies to provide you
          with a great experience and to help our website run effectively.
        </span>
        <div>
          <button
            type='button'
            className='btn btn--black btn--sm ml-4 whitespace-nowrap'
            onClick={onAcceptCookies}
          >
            Got it!
          </button>
        </div>
      </Transition>

      {children}

    </CookieConsentContext.Provider>
  );
};

CookieConsentProvider.propTypes = {
  children: PropTypes.node.isRequired
};