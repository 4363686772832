import React from 'react';

// This hook is used to manage render differences for server side vs client
// side rendering to avoid issues with React rehydration
// Refer to: https://blog.logrocket.com/fixing-gatsbys-rehydration-issue/

const useRenderEnvironment = () => {
  const [key, setKey] = React.useState('server');

  React.useEffect(() => {
    setKey('client');
  }, []);

  // Both are provided for convenience and readability (rather than negating one of the two)
  const isClient = React.useMemo(() => key === 'client', [key]);
  const isServer = React.useMemo(() => key === 'server', [key]);

  return {
    key,
    isClient,
    isServer
  };
};

export default useRenderEnvironment;