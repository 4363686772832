import React from 'react';
import PropTypes from 'prop-types';
import Link from 'components/LinkWithPrevUrl';

const LinkButton = React.forwardRef(({
  children,
  type,
  onClick,
  to,
  href,
  openInNewTab,
  icon: Icon,
  title,
  size,
  hasActiveState,
  active,
  disabled,
  color,
}, ref) => {

  let classes;
  if (size === 'sm') {
    classes = {
      text: 'text-sm font-normal',
      line: 'h-0.5 bottom-0'
    };
  } else if (size === 'base') {
    classes = {
      text: 'text-base font-medium',
      line: 'h-0.5 bottom-0'
    };
  } else if (size === 'md') {
    classes = {
      text: 'text-xl font-medium',
      line: 'h-0.5 bottom-0'
    };
  } else if (size === 'lg') {
    classes = {
      text: 'text-2xl font-medium',
      line: 'h-0.5 bottom-0'
    };
  } else if (size === 'xl') {
    classes = {
      text: 'text-3xl font-bold',
      line: 'h-0.75 bottom-0'
    };
  } 
  const textClassName = React.useMemo(() => {
    let className = `flex flex-row items-center transition-opacity ${(active || !hasActiveState) && !disabled ? 'opacity-100' : 'opacity-50'} ${classes.text}`;
    // Tailwind requires class names to exist in full to be included in css
    if (color === 'gpred') {
      className += ' text-gpred';
    } else if (color === 'blue') {
      className += ' text-sky-500';
    } else if (color === 'black') {
      className += ' text-zinc-900';
    } else if (color === 'gray') {
      className += ' text-zinc-500';
    } else if (color === 'white') {
      className += ' text-white';
    } else if (color === 'swiftyblue') {
      className += ' text-swiftyblue';
    } else if (color) {
      className += color
    }
    return className;
  }, [disabled, color, active, hasActiveState, classes]);

  const lineClassName = React.useMemo(() => {
    let className = `absolute bottom left-0 transform origin-100-50 scale-x-0 group-hover:origin-0-50 group-hover:scale-x-100 transition-transform ease-easeOutCirc duration-700 w-full ${active || !hasActiveState ? 'opacity-100' : 'opacity-50'} ${classes.line}`;
    if (disabled) {
      // Tailwind requires class names to exist in full to be included in css
      className += ' bg-gray-300';
    } else if (color === 'gpred') {
      className += ' bg-gpred';
    } else if (color === 'blue') {
      className += ' bg-sky-500';
    } else if (color === 'black') {
      className += ' bg-zinc-900';
    } else if (color === 'gray') {
      className += ' bg-zinc-500';
    } else if (color === 'white') {
      className += ' bg-white';
    } else if (color === 'swiftyblue') {
      className += ' bg-swiftyblue';
    } else if (color) {
      className += color
    }
    return className;
  }, [disabled, color, active, hasActiveState, classes]);

  const button = React.useMemo(() => {
    
    if (type === 'button') {
      return (
        <button type='button' onClick={onClick} className={textClassName}>
          {Icon && <Icon className="mr-1 mt-0.5"/>}
          <span>{children}</span>
        </button>
      );
    }

    if (type === 'gatsby-link') {
      return (
        <Link to={to} className={textClassName}>
          {Icon && <Icon className="mr-1 mt-0.5"/>}
          <span>{children}</span>
        </Link>
      );
    }

    if (type === 'standard-link') {
      return (
        <a
          href={href}
          title={title}
          className={textClassName}
          target={openInNewTab ? '_blank' : '_self'} rel="noreferrer"
        >
          {Icon && <Icon className="mr-1 mt-0.5"/>}
          <span>{children}</span>
        </a>
      );
    }

    console.error('Unknown link button type:', type);
    return null;
    
  }, [type, textClassName]);

  return (
    <span className={disabled ? 'opacity-50' : 'opacity-100'} ref={ref}>
      <span className={`z-0 group inline-block relative cursor-pointer ${(hasActiveState && active) || disabled ? 'pointer-events-none' : ''}`}>
        { button }
        <span className={lineClassName}/>
      </span>
    </span>
  );
});

LinkButton.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.oneOf(['button', 'gatsby-link', 'standard-link']).isRequired,
  onClick: PropTypes.func,
  to: PropTypes.string,
  href: PropTypes.string,
  openInNewTab: PropTypes.bool, // This is only applicable when the type is 'standard-link'
  icon: PropTypes.elementType,
  
  size: PropTypes.oneOf(['sm', 'base', 'md', 'lg', 'xl']),
  hasActiveState: PropTypes.bool,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  color: PropTypes.oneOf(['gpred', 'blue', 'gray', 'black', 'white','swiftyblue']),
  title: PropTypes.string,
};

LinkButton.defaultProps = {
  size: 'base',
  hasActiveState: false,
  active: false,
  disabled: false,
  color: 'gpred',
  onClick: () => {},
  to: null,
  href: null,
  openInNewTab: false,
  icon: null,
  title: null
};

export default LinkButton;